/*****************************
Page wide
*****************************/

@font-face {
  font-family: 'Helvetica LT std';
  src: url('./assets/fonts/HelveticaLTStd-Light.otf') format('opentype'),
    url('./assets/fonts/HelveticaLTStd-Light.woff') format('woff'),
    url('./assets/fonts/HelveticaLTStd-Light.woff2') format('woff2'),
    url('./assets/fonts/HelveticaLTStd-Light.ttf') format('truetype'),
    url('./assets/fonts/HelveticaLTStd-Light.svg#vtks_sonhoregular') format('svg'),
    url('./assets/fonts/HelveticaLTStd-Light.eot'),
    url('./assets/fonts/HelveticaLTStd-Light.eot?#iefix') format('embedded-opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica LT std';
  src: url('./assets/fonts/HelveticaLTStd-Bold.otf') format('opentype'),
    url('./assets/fonts/HelveticaLTStd-Bold.woff') format('woff'),
    url('./assets/fonts/HelveticaLTStd-Bold.woff2') format('woff2'),
    url('./assets/fonts/HelveticaLTStd-Bold.ttf') format('truetype'),
    url('./assets/fonts/HelveticaLTStd-Bold.svg#vtks_sonhoregular') format('svg'),
    url('./assets/fonts/HelveticaLTStd-Bold.eot'),
    url('./assets/fonts/HelveticaLTStd-Bold.eot?#iefix') format('embedded-opentype');
  font-display: swap;
  font-weight: bold;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*****************************
MUI Theme Overrides
*****************************/
.MuiDrawer-paperAnchorDockedRight {
  margin-top: 64px !important;
  height: calc(100vh - 64px) !important;
  @media screen and (max-width: 600px) {
    margin-top: 56px !important;
    height: calc(100vh - 56px) !important;
  }
}

label + .MuiInput-formControl {
  margin: 0 !important;
  margin-top: 8px !important;
}

.MuiBox-root,
.MuiList-root {
  padding: 0 !important;
}

.MuiInput-input,
.css-1uccc91-singleValue {
  font: unset !important;
  font-family: 'Helvetica LT std', 'Helvetica', 'Arial' !important;
}

.MuiTableCell-root {
  padding: 6px 16px;
  @media screen and (max-width: 960px) {
    padding: 8px !important;
  }
}

.MuiTab-root {
  text-transform: capitalize !important;
}

.MuiStepper-root {
  padding-top: 0 !important;
  padding-bottom: 24px !important;
}

.MuiDialogTitle-root {
  padding-bottom: 0 !important;
}

.MuiPickersToolbarText-toolbarTxt {
  padding-left: 24px !important;
}

.MuiChip-root {
  font-size: 12px !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  cursor: pointer;
}

.MUIDataTableToolbar-titleText {
  font-size: 16px !important;
}

/*****************************
Custom 
*****************************/

/* General */
.inline {
  display: inline !important;
}

.noPadding {
  padding: 0 !important;
}

.padding {
  padding: 24px !important;
}

.margin {
  margin: 24px !important;
}
.margin-top {
  margin-top: 24px !important;
}
.margin-right {
  margin-right: 24px !important;
}
.margin-bottom {
  margin-bottom: 24px !important;
}
.margin-left {
  margin-left: 24px !important;
}

/* font-size needs "!important" to override body font-size (16px) */
.bold {
  font-weight: bold !important;
}

.section-title {
  font-size: 1.25rem !important; // 20px
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}

.css-1m9dbsy-MuiTypography-root {
  font-size: 1.5rem !important;
}

.css-1z10yd4-MuiFormControl-root-MuiTextField-root {
  margin: 0px !important;
}
